import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import "rxjs/add/operator/map";
import * as moment from "moment";
import { Http, Headers } from "@angular/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { GeneralService } from "./general.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  /** Request */
  public headers: Headers;

  /** API Access - ENV */
  public baseUrl: string = "";

  constructor(
    public http: Http,
    private generalService: GeneralService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.baseUrl = environment.baseUrl;
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
  }

  // =================================
  //  Metodos
  // ================================

  /**
   * Asingación sesión
   * @param {any} user
   */
  public setAuthValues({ data }: { data: any }): boolean {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem("_user");
      localStorage.removeItem("_token");
      localStorage.removeItem("_expiresIn");

      const _token = `${data.token}`;
      const _expiresIn = moment().add(data.expiresIn, "second"); // seconds

      delete data.token;
      delete data.expiresIn;
      const _user = Object.assign({}, data);

      localStorage.setItem("_user", JSON.stringify(_user));
      localStorage.setItem("_token", _token);
      localStorage.setItem("_expiresIn", JSON.stringify(_expiresIn.valueOf()));
    }

    return true;
  }

  /**
   * User is admin PROGEN
   */
  public get isProgen(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const _user: any =
        JSON.parse(localStorage.getItem("_user" || "{}")) || {};
      return _user.tipo && _user.tipo === "PROGEN";
    }
    return false;
  }

  /**
   * User is admin PROGEN
   */
  public get isValidAccess(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const _user: any =
        JSON.parse(localStorage.getItem("_user" || "{}")) || {};
      return _user.tipo && (_user.tipo === "USER" || _user.tipo === "VENDEDOR");
    }
    return false;
  }

  /**
   * Retorna el usuario en sesión
   * @return {any} user
   */
  public getUser(): any {
    if (isPlatformBrowser(this.platformId)) {
      return JSON.parse(localStorage.getItem("_user"));
    }
    return {};
  }

  /**
   * Retorna el usuario en sesión
   * @return {any} user
   */
  public getToken(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem("token");
    }
    return "";
  }

  /**
   * Verifca si el usario está en sesión
   */
  public isLoggedIn(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const _user: any =
        JSON.parse(localStorage.getItem("_user" || "{}")) || {};
      const _token: string = localStorage.getItem("_token") || "";
      if (this.isValidAccess) {
        return _token && _user._id && moment().isBefore(this.getExpiration());
      }
    }
    return false;
  }

  /**
   * Expiration
   */
  private getExpiration() {
    if (isPlatformBrowser(this.platformId)) {
      const expiration = localStorage.getItem("_expiresIn");
      const expiresAt = JSON.parse(expiration);
      return moment(expiresAt);
    }
    return Date.now();
  }

  /**
   * Reotrna el usuario en sesión
   * @return {any} user
   */
  public logout(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem("_user");
      localStorage.removeItem("_token");
      localStorage.removeItem("_expiresIn");
      localStorage.removeItem("_items");
      localStorage.removeItem("MAYORISTA_ID");
      localStorage.removeItem("MAYORISTA_NAME");
      localStorage.removeItem("PUNTO_ID");
      localStorage.removeItem("PUNTO_NAME");
    }

    return true;
  }

  /**
   * API Generico POST
   * @param {string} api route: api/componente
   * @param {any} data objeto a persistir
   * @return {Observable<any>} respuesta asincrónica
   */
  public post({ api, data }: { api: String; data: any }): Promise<any> {
    const request = this.http
      .post(`${this.baseUrl}${api}`, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));

    return this.responseHandler({ request });
  }

  /**
   * Obtiene una respuesta del servidor
   * @param {Observable} request
   */
  private responseHandler({
    request,
  }: {
    request: Observable<any>;
  }): Promise<any> {
    return new Promise((resolve) => {
      request.subscribe(
        (response) => {
          if (response) {
            if (response.success) {
              if (response.data != undefined && response.data != null)
                resolve(response.data);
              else {
                this.generalService.handleError({
                  error: "Usuario no registrado.",
                });
                resolve(undefined);
              }
            } else {
              this.generalService.handleError({ error: response });
              resolve(undefined);
            }
          } else {
            this.generalService.handleResponseNull({});
            resolve(undefined);
          }
        },
        (error) => {
          const _body: any = JSON.parse(error._body || "{}");
          if (_body.message)
            this.generalService.handleError({
              error: `${_body.message}`,
            });
          else this.generalService.handleError({ error });
          resolve(undefined);
        }
      );
    });
  }
}
