import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Http, Headers } from "@angular/http";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { PublicModule } from "../public.module";
import { GeneralService } from "./general.service";

@Injectable({
  providedIn: "root",
})
export class MainService {
  /** Request */
  public headers: Headers;

  /** API Access - ENV */
  public baseUrl: string = "";

  constructor(
    public http: Http,
    private generalService: GeneralService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.baseUrl = environment.baseUrl;
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append("X-CSCAPI-KEY", environment.countriesKey);
  }

  // =================================
  // SERVICES
  // ================================

  /**
   * Obtiene una respuesta del servidor
   * @param {Observable} request
   */
  private responseHandler({
    request,
    hide404,
  }: {
    request: Observable<any>;
    hide404?: boolean;
  }): Promise<any> {
    return new Promise((resolve) => {
      request.subscribe(
        (response) => {
          if (response) {
            if (response.success) {
              if (response.data != undefined && response.data != null)
                resolve(response.data);
              else {
                if (!hide404)
                  this.generalService.handleError({
                    error: "404 Objeto No Encontrado.",
                  });
                resolve(undefined);
              }
            } else {
              this.generalService.handleError({ error: response });
              resolve(undefined);
            }
          } else {
            this.generalService.handleResponseNull({});
            resolve(undefined);
          }
        },
        (error) => {
          const _body: any = JSON.parse(error._body || "{}");
          if (_body.message)
            this.generalService.handleError({
              error: `${_body.message}`,
            });
          else this.generalService.handleError({ error });
          resolve(undefined);
        }
      );
    });
  }

  /**
   * API Generico GET
   * @param {string} api route: api/componente
   * @return {Promise<any>} respuesta asincrónica
   */
  public get({
    api,
    hide404,
  }: {
    api: String;
    hide404?: boolean;
  }): Promise<any> {
    const request = this.http
      .get(`${this.baseUrl}${api}`, { headers: this.headers })
      .pipe(map((res) => res.json()));

    return this.responseHandler({ request, hide404: hide404 });
  }

  /**
   * API Generico POST
   * @param {string} api route: api/componente
   * @param {any} data objeto a persistir
   * @return {Observable<any>} respuesta asincrónica
   */
  public post({ api, data }: { api: String; data: any }): Promise<any> {
    const request = this.http
      .post(`${this.baseUrl}${api}`, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));

    return this.responseHandler({ request });
  }

  /**
   * API Generico DELETE
   * @param {string} api route: api/componente/id
   * @return {Observable<any>} respuesta asincrónica
   */
  public delete({ api }: { api: String }): Promise<any> {
    const request = this.http
      .delete(`${this.baseUrl}${api}`, { headers: this.headers })
      .pipe(map((res) => res.json()));

    return this.responseHandler({ request });
  }

  /**
   * API Generico PUT
   * @param {string} api route: api/componente/id
   * @param {any} data propiedades a actualizar
   * @return {Observable<any>} respuesta asincrónica
   */
  public put({ api, data }: { api: String; data: any }): Promise<any> {
    const request = this.http
      .put(`${this.baseUrl}${api}`, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));

    return this.responseHandler({ request });
  }

  /**
   * API Generico GET
   * @param {string} api route: api/componente
   * @return {Observable<any>} respuesta asincrónica
   */
  public getRaw({ api }: { api: String }): Observable<any> {
    const request = this.http
      .get(`${api}`, { headers: this.headers })
      .pipe(map((res) => res.json()));

    return request;
  }
}
