import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

import { LoginComponent } from "./public/login/login.component";
import { AuthGuard } from "./public/guards/auth.guard";

export const routes: Routes = [
  {
    path: "index",
    loadChildren: () =>
      import("./public/public.module").then((m) => m.PublicModule),
    // canActivate: [AuthGuard],
  },

  { path: "", redirectTo: "index", pathMatch: "full" },
  { path: "**", redirectTo: "index" },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
