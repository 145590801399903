import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { GeneralService } from "../services/general.service";
import { AuthService } from "../services/auth.service";
import { catchError } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private generalService: GeneralService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err) {
          if (err.status === 401) {
            this.generalService.showToastr({
              title: "Tu sesión ha expirado, por favor ingresa de nuevo.",
              status: "danger",
            });
            this.authService.logout();
            this.generalService.routerNavigateTo({ path: "/index" });
          }
          const error = err.error.message || err.statusText;
          return throwError(error);
        }
      })
    );
  }
}
